import axios from '@axios'
import { INVOICE_LIST, INVOICE_SHOW } from '@core/services/api.js'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchInvoices(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_BASEURL + INVOICE_LIST, { params: queryParams })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchInvoice(ctx, { invoiceId }) {
            return new Promise((resolve, reject) => {
                axios
                    .get(process.env.VUE_APP_BASEURL + INVOICE_SHOW + `/${invoiceId}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchClients() {
            return new Promise((resolve, reject) => {
                axios
                    .get('/apps/invoice/clients')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        // addUser(ctx, userData) {
        //   return new Promise((resolve, reject) => {
        //     axios
        //       .post('/apps/user/users', { user: userData })
        //       .then(response => resolve(response))
        //       .catch(error => reject(error))
        //   })
        // },
    },
}